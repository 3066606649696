<template>
  <div>
    <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>商品管理</template>
      <template v-slot:secondMenu>店铺管理</template>
    </breadcrumb-nav>
    <el-alert style="margin-bottom: 10px;" type="warning" title="注意：创建店铺前请先创建店铺管理员。" :closable="false" show-icon></el-alert>
    <!-- 卡片视图-->
    <el-card class="box-card">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="管理员" name="admin">
          <shop-admin></shop-admin>
        </el-tab-pane>
        <el-tab-pane label="店铺" name="shop">
          <!-- 顶部搜索与添加按钮 -->
          <shop-search-and-add :listQuery="listQuery" @shop-list="updateShopList"/>

          <!-- 展示的表格 -->
          <shop-table :shop-list="shopList" :list-query="listQuery" @shop-list="updateShopList"/>

          <!-- 分页 -->
          <shop-pagination :list-query="listQuery" :total="total" @page-size-change="handleSizeChange"
                           @current-page-change="handleCurrentChange"/>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import ShopSearchAndAdd from './shopChildComponents/ShopSearchAndAdd'
import ShopTable from './shopChildComponents/ShopTable'
import ShopPagination from './shopChildComponents/ShopPagination'
import ShopAdmin from './shopChildComponents/ShopAdmin'
import {
  getShopList
} from '../../network/shop'

export default {
  name: 'Shop',
  components: {
    BreadcrumbNav,
    ShopSearchAndAdd,
    ShopTable,
    ShopPagination,
    ShopAdmin
  },
  data () {
    return {
      listQuery: {
        name: '',
        page: 1,
        size: 10,
        shopType: 0
      },
      shopList: [],
      total: 0,
      activeName: 'admin'
    }
  },
  created () {
    // this.getShopList()
  },
  methods: {
    // 获取用户列表
    getShopList () {
      getShopList(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取商铺列表获取失败', 'error')
        }
        this.shopList = result.data.list
        for (const item of this.shopList) {
          if (item.logo.includes('http')) {
            item.imageUrl = item.logo
          } else {
            item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.logo
          }
        }
        console.log('this.shoplist:', this.shopList)
        this.total = result.data.total
      })
    },
    handleTabClick () {
      console.log('this.activeName:', this.activeName)
      switch (this.activeName) {
        case 'admin':
          break
        case 'shop':
          this.getShopList()
      }
    },
    // 监听子组件发射的事件，获得最新的userList和total信息
    updateShopList () {
      this.getShopList()
    },

    // 监听pageSize改变的事件，并发送新的网络请求
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getShopList()
    },

    // 监听页码改变的事件，并发送新的网络请求
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getShopList()
    }
  }
}
</script>

<style scoped>
</style>
