import { request } from './request'

// 获取店铺列表
export function getShopList (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/shop/list',
    method: 'get',
    params
  })
}

// 添加店铺
export function addShop (addInfo) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/shop/add',
    method: 'post',
    data: { ...addInfo }
  })
}

// 获取指定shopId的店铺
export function getShop (shopId) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/shop/detail',
    params: {
      shopId: shopId
    },
    method: 'get'
  })
}

// 修改店铺
export function updateShop (shopInfo) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/shop/update',
    method: 'put',
    data: {
      name: shopInfo.name,
      code: shopInfo.code,
      desc: shopInfo.desc,
      logo: shopInfo.logo,
      star: shopInfo.star,
      rating: shopInfo.rating,
      shopId: shopInfo.shopId,
      adminId: shopInfo.adminId,
      hots: shopInfo.hots
    }
  })
}

// 删除店铺
export function deleteShop (shopId) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/shop/${shopId}`,
    method: 'delete'
  })
}

// 获取店铺列表
export function getShopAdminList (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/shop/admin/list',
    method: 'get',
    params
  })
}

// 添加店铺
export function addShopAdmin (addInfo) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/shop/admin/add',
    method: 'post',
    data: { ...addInfo }
  })
}

// 获取指定shopId的店铺
export function getShopAdmin (id) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/shop/admin/detail',
    params: {
      id: id
    },
    method: 'get'
  })
}

// 修改店铺
export function updateShopAdmin (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/shop/admin/update',
    method: 'put',
    data: {
      name: info.name,
      icon: info.icon,
      mobile: info.mobile,
      password: info.password,
      id: info.id
    }
  })
}
