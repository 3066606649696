<template>
  <div class="editDialog">
    <!-- 修改店铺按钮 -->
    <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(shopId)">编辑</el-button>

    <!-- 修改店铺对话框 -->
    <el-dialog
      title="修改商铺"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClose">
      <el-form :model="editShopForm" :rules="editShopFormRules" ref="editShopFormRef" label-width="90px">
        <el-form-item label="商铺名称" prop="name">
          <el-input v-model="editShopForm.name" ></el-input>
        </el-form-item>
        <el-form-item label="商铺编码" prop="code">
          <el-input v-model="editShopForm.code"></el-input>
        </el-form-item>
        <el-form-item label="管理员" prop="adminId">
          <el-select v-model="editShopForm.adminId" placeholder="管理员选择" style="width:100%">
            <el-option
              v-for="item in adminList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺热搜" prop="desc">
          <el-input v-model="editShopForm.hots"></el-input>
        </el-form-item>
        <el-form-item label="店铺描述" prop="desc">
          <el-input v-model="editShopForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="服务星级" prop="star">
          <el-rate style="display: inline-block;" v-model="editShopForm.star"></el-rate>
        </el-form-item>
        <el-form-item label="宝贝质量" prop="ratingShop">
          <el-input type="Number" v-model="editShopForm.ratingShop"></el-input>
        </el-form-item>
        <el-form-item label="客服质量" prop="ratingCustomer">
          <el-input type="Number" v-model="editShopForm.ratingCustomer"></el-input>
        </el-form-item>
        <el-form-item label="售后服务" prop="ratingAfter">
          <el-input type="Number" v-model="editShopForm.ratingAfter"></el-input>
        </el-form-item>
        <el-form-item label="店铺图标" prop="logo">
          <uploader ref="uploader" :file-list="fileList" :limit-count="limitCount" :hide-upload=true @remove-success="handleRemove" @upload-success="handleSuccess"></uploader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateShopInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {
  getShop,
  updateShop,
  getShopAdminList
} from '../../../network/shop'

// import { deleteMedia } from '../../../network/media'
import Uploader from '../../common/Uploader'

export default {
  name: 'ShopEditDialog',
  components: {
    Uploader
  },
  props: {
    shopId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      editDialogVisible: false,
      editShopForm: {
        shopId: 0,
        name: '',
        code: '',
        desc: '',
        logo: '',
        star: 0,
        adminId: '',
        ratingShop: 0,
        ratingCustomer: 0,
        ratingAfter: 0,
        hots: ''
      },
      editShopFormRules: {
        name: [
          {
            required: true,
            message: '请输入店铺名称'
          }
        ],
        /* code: [
          {
            required: true,
            message: '请输入店铺编码'
          }
        ], */
        logo: [
          {
            required: true,
            message: '请上传店铺图标'
          }
        ],
        adminId: [
          {
            required: true,
            message: '请选择管理员'
          }
        ]
      },
      fileList: [],
      limitCount: 1,
      adminList: []
    }
  },
  methods: {
    // 展示编辑用户的对话框，并获取当前用户的数据
    showEditDialog (shopId) {
      this.editDialogVisible = true
      getShopAdminList().then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取商铺信息失败', 'error')
        }
        this.adminList = result.data.list
        this.getShopDetail(shopId)
      })
    },
    getShopDetail (shopId) {
      getShop(shopId).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取商铺信息失败', 'error')
        }
        this.editShopForm.shopId = result.data.shopId
        this.editShopForm.name = result.data.name
        this.editShopForm.logo = result.data.logo
        this.editShopForm.star = result.data.star
        this.editShopForm.code = result.data.code
        this.editShopForm.desc = result.data.desc
        this.editShopForm.adminId = result.data.adminId
        if (this.editShopForm.adminId === 0) {
          this.editShopForm.adminId = ''
        }
        this.editShopForm.hots = result.data.hots
        for (const item of result.data.rating) {
          switch (item.title) {
            case '宝贝质量':
              this.editShopForm.ratingShop = item.value
              break
            case '客服质量':
              this.editShopForm.ratingCustomer = item.value
              break
            case '售后服务':
              this.editShopForm.ratingAfter = item.value
              break
            default:
              break
          }
        }
        if (result.data.logo !== '') {
          this.fileList = [
            {
              url: window.g.MEDIA_DOWNLOAD_URL + result.data.logo,
              remote: true
            }
          ]
        }
        console.log('showEditDialog editShopForm is:', this.editShopForm)
      })
    },
    // 隐藏上传操作
    hideUploadPlus () {
      // this.$refs.uploader.hideUploadPlus()
    },

    // 监听编辑用户的对话框的关闭事件
    editDialogClose () {
      this.$refs.editShopFormRef.resetFields()
    },

    // 点击编辑店铺的“确定”按钮
    updateShopInfo () {
      this.$refs.editShopFormRef.validate(valid => {
        if (!valid) return
        const shopItem = {
          shopId: this.editShopForm.shopId,
          name: this.editShopForm.name,
          code: this.editShopForm.code,
          desc: this.editShopForm.desc,
          logo: this.editShopForm.logo,
          star: this.editShopForm.star,
          adminId: this.editShopForm.adminId,
          rating: [],
          hots: this.editShopForm.hots
        }
        shopItem.rating.push(
          {
            title: '宝贝质量',
            value: Math.round(parseFloat(this.editShopForm.ratingShop) * Math.pow(10, 2)) / Math.pow(10, 2)
          })
        shopItem.rating.push(
          {
            title: '客服质量',
            value: Math.round(parseFloat(this.editShopForm.ratingCustomer) * Math.pow(10, 2)) / Math.pow(10, 2)
          })
        shopItem.rating.push(
          {
            title: '售后服务',
            value: Math.round(parseFloat(this.editShopForm.ratingAfter) * Math.pow(10, 2)) / Math.pow(10, 2)
          })
        updateShop(shopItem).then(res => {
          // 更新失败
          if (res.status !== 200) {
            return this.alertMessage('更新商铺信息失败', 'error')
          }
          // 提示更新成功
          this.alertMessage('更新商铺信息成功', 'success')
          // 关闭对话框
          this.editDialogVisible = false
          // 重新获取店铺列表
          this.$emit('shop-list')
        })
      })
    },

    handleRemove (files) {
      console.log('uploader emit remove succ: ', files)
      this.editShopForm.logo = ''
    },
    handleSuccess (files) {
      console.log('uploader emit upload succ: ', files)
      this.editShopForm.logo = files[0].mediaId
    }
  }
}
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
</style>
