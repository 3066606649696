<template>
  <div>
    <!-- 搜索与添加按钮 -->
    <el-row :gutter="20">
      <el-col :span="8">
        <!-- 搜索与添加区域 -->
        <el-input placeholder="店铺名称" v-model="listQuery.name" clearable @clear="search">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="showAddDialog">添加店铺</el-button>
      </el-col>
    </el-row>

    <!-- 添加站点的对话框 -->
    <el-dialog
      title="添加店铺"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose">
      <el-form :model="addShopForm" :rules="addShopFormRules" ref="addShopFormRef" label-width="90px">
        <el-form-item label="店铺名称" prop="name">
          <el-input v-model="addShopForm.name"></el-input>
        </el-form-item>
        <el-form-item label="店铺编码" prop="code">
          <el-input v-model="addShopForm.code"></el-input>
        </el-form-item>
        <el-form-item label="管理员" prop="adminId">
          <el-select v-model="addShopForm.adminId" placeholder="管理员选择" style="width:100%">
            <el-option
              v-for="item in adminList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺热搜" prop="desc">
          <el-input v-model="addShopForm.hots"></el-input>
        </el-form-item>
        <el-form-item label="店铺描述" prop="desc">
          <el-input v-model="addShopForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="服务星级" prop="star">
          <el-rate style="display: inline-block;" v-model="addShopForm.star"></el-rate>
        </el-form-item>
        <el-form-item label="宝贝质量" prop="ratingShop">
          <el-input type="Number" v-model="addShopForm.ratingShop"></el-input>
        </el-form-item>
        <el-form-item label="客服质量" prop="ratingCustomer">
          <el-input type="Number" v-model="addShopForm.ratingCustomer"></el-input>
        </el-form-item>
        <el-form-item label="售后服务" prop="ratingAfter">
          <el-input type="Number" v-model="addShopForm.ratingAfter"></el-input>
        </el-form-item>
        <el-form-item label="店铺图标" prop="logo">
          <el-upload ref="adModel" :on-change="handleChange" :limit="limitCount" :action="uploadUrl" :headers="imgUploadHeaders" list-type="picture-card" :auto-upload="true" :on-success="handleImgUploadSuccess" :on-error="handleImgUploadError" :file-list="fileList" :class="{hide:hideUpload}">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{file}">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
            <span class="el-upload-list__item-actions">
                  <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleImgRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
          </div>
        </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddShop">取 消</el-button>
        <el-button type="primary" @click="addShop">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { addShop, getShopAdminList } from '../../../network/shop'

export default {
  name: 'ShopSearchAndAdd',
  props: {
    listQuery: {
      type: Object,
      default () {
        return {
          name: '',
          page: 1, // 当前页
          size: 10 // 每页显示多少条数据
        }
      }
    }
  },
  data () {
    return {
      addDialogVisible: false,
      // 添加店铺表单的数据
      addShopForm: {
        name: '',
        code: '',
        desc: '',
        logo: '',
        star: 0,
        ratingShop: 0,
        ratingCustomer: 0,
        ratingAfter: 0,
        adminId: '',
        hots: ''
      },
      addShopFormRules: {
        name: [
          {
            required: true,
            message: '请输入店铺名称',
            trigger: 'blur'
          }
        ],
        /* code: [
          {
            required: true,
            message: '请输入店铺编码',
            trigger: 'blur'
          }
        ], */
        logo: [
          {
            required: true,
            message: '请上传店铺图标'
          }
        ],
        adminId: [
          {
            required: true,
            message: '请选择管理员',
            trigger: 'blur'
          }
        ]
      },
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      fileList: [],
      disabled: false,
      limitCount: 1,
      hideUpload: false, // 是否隐藏上传,
      adminList: []
    }
  },
  methods: {
    // 点击搜索按钮时，通知外部组件重新获取商铺列表
    search () {
      this.$emit('shop-list')
    },
    showAddDialog () {
      this.addDialogVisible = true
      getShopAdminList().then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取商铺信息失败', 'error')
        }
        this.adminList = result.data.list
      })
    },
    // 监听弹框的关闭事件
    addDialogClose () {
      console.log('addDialogClose')
      this.$refs.addShopFormRef.resetFields()
      this.$refs.adModel.clearFiles()
    },
    cancelAddShop () {
      this.addShopForm.logo = ''
      this.hideUpload = false
      this.addDialogVisible = false
    },
    addShop () {
      this.$refs.addShopFormRef.validate(valid => {
        if (!valid) return
        const shopItem = {
          name: this.addShopForm.name,
          code: this.addShopForm.code,
          desc: this.addShopForm.desc,
          logo: this.addShopForm.logo,
          star: this.addShopForm.star,
          adminId: this.addShopForm.adminId,
          rating: [],
          hots: this.addShopForm.hots
        }
        shopItem.rating.push(
          {
            title: '宝贝质量',
            value: Math.round(parseFloat(this.addShopForm.ratingShop) * Math.pow(10, 2)) / Math.pow(10, 2)
          })
        shopItem.rating.push(
          {
            title: '客服质量',
            value: Math.round(parseFloat(this.addShopForm.ratingCustomer) * Math.pow(10, 2)) / Math.pow(10, 2)
          })
        shopItem.rating.push(
          {
            title: '售后服务',
            value: Math.round(parseFloat(this.addShopForm.ratingAfter) * Math.pow(10, 2)) / Math.pow(10, 2)
          })
        addShop(shopItem).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('添加商铺失败', 'error')
          }
          this.alertMessage('添加商铺成功', 'success')
          this.addDialogVisible = false
          this.$emit('shop-list')
        })
      })
    },

    handleChange (file, fileList) {
      this.fileList = fileList
      this.hideUpload = this.fileList.length >= this.limitCount
    },
    // 处理移除图片的操作
    handleImgRemove (file) {
      this.fileList = this.fileList.filter(x => x.uid !== file.uid)
      console.log('handleImgRemove:', this.fileList)
      this.hideUpload = this.fileList.length >= this.limitCount
      this.addShopForm.logo = ''
    },

    // 监听图片上传成功
    handleImgUploadSuccess (response, file, fileList) {
      console.log('upload succ:', response, ';file:', file, ';fileList:', fileList)
      this.fileList = fileList
      this.addShopForm.logo = response.data.list[0].mediaId
    },
    handleImgUploadError (response, file, fileList) {
      console.log('upload err:', response)
      return this.alertMessage('上传文件失败', 'error')
    }
  }
}
</script>

<style scoped>
/deep/.hide .el-upload--picture-card {
  display: none;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
