<template>
  <el-table class="custom-shop-table" :data="shopList" border stripe>
    <!-- 基本信息 -->
    <el-table-column type="index" label="#" width="50px"></el-table-column>
    <el-table-column prop="name" label="店铺名称" width="120px"></el-table-column>
    <el-table-column prop="hots" label="店铺热搜" width="120px"></el-table-column>
    <el-table-column prop="adminId" label="管理员" width="120px" :formatter="showAdminName"></el-table-column>
    <el-table-column prop="star" label="服务星级" width="180px">
      <template slot-scope="scope">
        <el-rate
          v-model=scope.row.star
          disabled
          >
        </el-rate>
      </template>
    </el-table-column>
    <el-table-column prop="rating" label="服务评价">
      <template slot-scope="scope">
        <el-tag v-for="(item,i) in scope.row.rating" :key="i">{{item.title}}:{{item.value}}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="logo" label="店铺图标" width="120px">
      <template slot-scope="scope">
        <el-image
          style="height: 50px; width: 50px"
          :src="scope.row.imageUrl"
        ></el-image>
      </template>
    </el-table-column>
    <!-- 操作 -->
    <el-table-column label="操作" width="200px" class="btns">
      <template slot-scope="scope">
        <shop-edit-dialog :shopId="scope.row.shopId" @shop-list="updateShopList"/>
        <shop-remove-dialog :shopId="scope.row.shopId" @shop-list="updateShopList"/>
      </template>
    </el-table-column>

  </el-table>
</template>

<script>

import ShopEditDialog from './ShopEditDialog'
import ShopRemoveDialog from './ShopRemoveDialog'
import { getShopAdminList } from '../../../network/shop'

export default {
  name: 'ShopTable',
  components: {
    ShopEditDialog,
    ShopRemoveDialog
  },
  filters: {
  },
  props: {
    shopList: {
      type: Array,
      default () {
        return []
      }
    },
    listQuery: {
      type: Object,
      default () {
        return {
          name: '',
          page: 1,
          size: 10
        }
      }
    }
  },
  data () {
    return {
      adminList: []
    }
  },
  mounted () {
    getShopAdminList({}).then(res => {
      const result = res.data
      if (res.status !== 200) {
        return this.alertMessage('获取管理员列表失败', 'error')
      }
      this.adminList = result.data.list
    })
  },
  methods: {
    // 监听子组件发射的事件，获得最新的shopList和total信息
    updateShopList () {
      this.$emit('shop-list')
    },
    showAdminName (row, column, cellValue) {
      for (const item of this.adminList) {
        if (item.id === cellValue) {
          return item.name
        }
      }
      if (cellValue === 0) {
        return ''
      }
      return cellValue
    }
  }
}
</script>

<style scoped>
.custom-shop-table {
  margin-top: 20px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
