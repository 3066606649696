<template>
  <div>
    <el-upload
      :action="uploadUrl"
      :limit="limitCount"
      :headers="imgUploadHeaders"
      :multiple="multiple"
      list-type="picture-card"
      :on-change="handleChange"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-error="handleError"
      :file-list="fileList"
      :class="{hide:hideUpload}"
      >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>

// import { deleteMedia } from '../../network/media'

export default {
  name: 'Uploader',
  props: {
    limitCount: {
      type: Number,
      default: 10000
    },
    multiple: {
      type: Boolean,
      default: false
    },
    fileList: {
      type: Array,
      default () {
        return []
      }
    }/*,
    hideUpload: {
      type: Boolean,
      default: false
    } */
  },
  /* computed: {
    hideUpload: {
      get () {
        console.log('computed file:', this.fileList.length)
        return this.uploadFiles.length >= this.limitCount
      },
      set (val) {}
    }
  }, */
  watch: {
    // 监听初始时图片列表长度, 与limit相等则隐藏按钮
    fileList: {
      handler () {
        console.log('watch this.fileList.length:', this.fileList.length)
        this.uploadFiles = this.fileList
        this.hideUpload = this.uploadFiles.length >= this.limitCount
      }
    }
  },
  data () {
    return {
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      disabled: false,
      hideUpload: false,
      dialogImageUrl: '',
      dialogVisible: false,
      uploadFiles: []
    }
  },
  methods: {
    handleChange (file, fileList) {
      this.uploadFiles = fileList
      this.hideUpload = this.uploadFiles.length >= this.limitCount
      console.log('|||||this limit', this.limitCount, ',handle change:', this.hideUpload, ';---up:', this.uploadFiles)
    },
    handlePreview (file) {
      // 将图片地址赋值给这个属性
      this.dialogImageUrl = file.url
      // 对话框显示
      this.dialogVisible = true
    },
    remoteRemove (list) {
      this.$emit('remove-success', list)
      // 不做物理删除，minio里很久不用的数据可以写定时任务清除
      /* for (const item of list) {
        deleteMedia(item.mediaId).then(res => {
          console.log('deleteMedia:', item.mediaId, ';res:', res)
          if (res.status !== 200) {
            console.log('删除文件:', item.mediaId, '失败')
          } else {
            console.log('删除文件:', item.mediaId, '成功')
          }
        })
      } */
    },
    handleRemove (file, fileList) {
      // file参数:代表的是删除的那个张图片
      // fileList:照片墙删除某一张图片以后，剩余的其他的图片
      // 收集照片墙图片的数据
      // 对于已有的图片【照片钱中显示的图片：有name、url字段的】，因为照片墙显示数据务必要有这两个属性
      // 对于服务器而言，不需要name、url字段，将来对于有的图片的数据在提交给服务器的时候，需要处理的
      this.uploadFiles = fileList
      console.log('before limit:', this.limitCount, ',remove file is:', file, ',fileList len:', fileList.length, ',uploadlen:', this.uploadFiles.length)
      this.hideUpload = this.uploadFiles.length >= this.limitCount
      console.log('this handle handleRemove:', this.hideUpload, ';---up:', this.uploadFiles.length)
      console.log('after limit:', this.limitCount, ',remove file is:', file, ',fileList:', fileList.length, ',uploadlen:', this.uploadFiles.length)
      if (file && file.response && file.response.data && file.response.data.list && file.response.data.list.length > 0) {
        this.remoteRemove(file.response.data.list)
      } else if (file.remote) {
        this.remoteRemove(file)
      }
    },

    // 照片墙图片上传成功的回调
    handleSuccess (response, file, fileList) {
      // 收集图片的信息
      this.uploadFiles = fileList
      this.hideUpload = this.uploadFiles.length >= this.limitCount
      if (response.data && response.data.list && response.data.list.length > 0) {
        this.$emit('upload-success', response.data.list)
      }
      console.log('this handle handlesucc:', this.hideUpload, ';---up:', this.uploadFiles)
    },

    handleError (response, file, fileList) {
      console.log('upload err:', response)
      return this.alertMessage('上传文件失败', 'error')
    },
    hideUploadPlus () {
      console.log('init file:', this.uploadFiles.length, ', list:', this.fileList)
      // this.hideUpload = true
    }
  }
}
</script>

<style scoped>
/deep/ .hide .el-upload--picture-card {
  display: none;
}
</style>
